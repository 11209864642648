<div class="main_content_position">
    <div class="page__header">
        <div class="page__header_title">
            About
        </div>
        <div class="page__header_link">
            <span class="page__header_link_text" routerLink="/">Home</span>
            <span class="material-icons page__header_link-icon">chevron_right</span>
            <span class="page__header_link_text">About</span>
        </div>
    </div>
</div>

<div class="about">
    <div class="about__flex">
        <div class="about__flex_image">
            <img src="../../assets/about-2.png" alt="About us">
        </div>
        <div class="about__flex_info">
            AEROPS Facility Management PVT.LTD. was established in India in the year 2021, and since then, we have
            rapidly evolved into a dynamic and forward-thinking organization. Our team comprises highly experienced
            Directors, proficient Team Leaders, and dedicated Members, all contributing their wealth of expertise to our
            operations. AEROPS stands as a burgeoning force in operations and maintenance services specifically tailored
            for airports.
        </div>
    </div>

    <div class="about__flex">
        <div class="about__flex_info">
            <h1 class="about__title" style="margin-top: 0;">BCAS Certified Pioneers in Airport Operations and
                Maintenance</h1>
            <div style="line-height: 24px;">
                At AEROPS, we take pride in being recognized and certified by the
                <span style="font-weight: bold;">
                    Bureau of Civil Aviation Security (BCAS) authority,
                </span>
                a testament to our unwavering commitment to upholding the highest standards of quality and
                security. We offer a comprehensive range of services, including expert installation and servicing of
                conveyor systems from major manufacturers, covering a wide expanse across India.
            </div>
        </div>

        <div class="about__flex_image">
            <img src="../../assets/BCAS_logo.png" alt="About us">
        </div>
    </div>

    <div class="about__flex">
        <div>
            <h1 class="about__title">
                <img class="about__title_image" src="../../assets/mission.png" alt="Our Mission">
                Our Mission
            </h1>

            <div class="about__info">
                AEROPS's mission is so clear. Our aim is to provide the worlds best services and management facilities
                to our
                clients.
                Because we believe that our work is our own identity.
            </div>
        </div>
        <div>
            <h1 class="about__title">
                <img class="about__title_image" src="../../assets/vision.png" alt="Our Vision">
                Our Vision
            </h1>

            <div class="about__info">
                In future, We are setting the target to become more customer centric and focussed company, because
                customer
                satisfaction is nothing but the master key
                to our success.
            </div>
        </div>
    </div>
</div>