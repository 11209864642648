<main>
    <!-- hero section -->
    <section>
        <div class="hero_image">
            <div class="hero_image_subtitle">
                One stop solution for
            </div>

            <div class="hero_image_title">
                Material Handling<br>Conveyor System
            </div>

            <!-- <button class="hero_image_btn" routerLink="/services">
                <span>Explore Us</span>
            </button> -->
        </div>
    </section>
    <!-- hero section end -->

    <!-- content section -->
    <section>
        <div class="info">
            <strong>AEROPS Facility Management</strong> is an emerging operations and maintainance firm for
            airports.<br>
            We are highly regarded full service company that expertly installs and services major manufacturer's
            brands of conveyor systems
            throughout the India.
        </div>

        <!-- about section -->
        <div class="section">
            <div class="about">
                <div class="about__flex">
                    <div class="about__flex_image">
                        <img src="../../assets/belt1.jpg" alt="about us">
                    </div>
                    <div class="about__flex_info">
                        <h1 class="about__title">We Are</h1>
                        <div class="about__flex_info_text">
                            In 2021, we established ourselves as a dynamic and promising company, led by a team of
                            seasoned professionals with substantial expertise in material handling system installation,
                            operation, maintenance, and facility management.
                            <br><br>
                            We are proud to highlight that our
                            commitment to excellence has been recognized and certified by the
                            <span style="font-weight: bold;">
                                Bureau of Civil Aviation Security (BCAS) authority.
                            </span>
                        </div>

                        <button class="about__flex_info_btn" routerLink="/about">
                            <span class="about__flex_info_btn_text">Know More</span>
                            <span class="material-icons about__flex_info_btn-icon">arrow_forward</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- about section end -->

        <!-- service section -->
        <div class="section">
            <div class="service">
                <h1 class="service__title">We Offer</h1>
                <!-- <div class="service__flex_info_text">
                    We offers wide range of services for various equipments at airports.
                    Including Installation and Maintainance of Mechanical Conveyor, Airport BHS
                    Design/Documentation, Airport System Consultancy.
                </div> -->
                <div class="service__summary">
                    <div class="service__summary_grid">
                        <div class="service__summary_grid_block">
                            <img src="../../assets/maintainance.png" class="service__summary_grid_block_icon" alt="">
                            <div class="service__summary_grid_block_title">
                                Conveyor System Installation and Maintainance
                            </div>
                            <div class="service__summary_grid_block_text">
                                We are not only good at installing the Conveyor system and its related equipments,
                                But also good at taking care of them.
                            </div>
                        </div>
                        <div class="service__summary_grid_block">
                            <img src="../../assets/operations.png" class="service__summary_grid_block_icon" alt="">
                            <div class="service__summary_grid_block_title">
                                BHS Operation & Maintainance
                            </div>
                            <div class="service__summary_grid_block_text">
                                AEROPS has professional technicians who are expert at the design and architecture of
                                BHS.
                            </div>
                        </div>
                        <div class="service__summary_grid_block">
                            <img src="../../assets/design.png" class="service__summary_grid_block_icon" alt="">
                            <div class="service__summary_grid_block_title">
                                Airport BHS Design
                            </div>
                            <div class="service__summary_grid_block_text">
                                We design fully customizable and up-to-date Baggage Handling System (BHS).
                                AEROPS use the latest technology in market to create/implement the design.
                            </div>
                        </div>
                        <div class="service__summary_grid_block">
                            <img src="../../assets/consultancy.png" class="service__summary_grid_block_icon" alt="">
                            <div class="service__summary_grid_block_title">
                                Airport System Consultancy
                            </div>
                            <div class="service__summary_grid_block_text">
                                AEROPS provides wide range of consultation service for its Clients/Industry time to
                                time.
                            </div>
                        </div>
                    </div>
                </div>

                <button class="service__btn" routerLink="/services">
                    <span class="service__btn_text">Know More</span>
                    <span class="material-icons service__btn-icon">arrow_forward</span>
                </button>
            </div>
        </div>
        <!-- service section end -->

        <!-- contact section -->
        <div class="contact">
            <div class="contact__info">
                <div class="contact__info_text">
                    We would like to here from you
                </div>
                <button class="contact__info_btn" routerLink="/contact">
                    <span class="contact__info_btn_text">Contact Us</span>
                    <span class="material-icons contact__info_btn-icon">arrow_forward</span>
                </button>
            </div>
            <div class="contact_image">
                <img src="../../assets/contact-us.png" alt="">
            </div>
        </div>
        <!-- contact section end -->

    </section>
    <!-- content section -->
</main>