<div class="main_content_position">
    <div class="page__header">
        <div class="page__header_title">
            Accomplishments
        </div>
        <div class="page__header_link">
            <span class="page__header_link_text" routerLink="/">Home</span>
            <span class="material-icons page__header_link-icon">chevron_right</span>
            <span class="page__header_link_text">Accomplishments</span>
        </div>
    </div>
    <div class="container">
        <div class="success">
            <div class="success__image">
                <img src="../../assets/success.png" alt="Successful Completion">
            </div>
            <div class="success__para">
                Our journey in the realm of airport facility management has been marked by a series of successful and
                impactful projects, highlighting our commitment to excellence and innovation.
                <br>
                <br>
                Our team has successfully executed projects involving the installation, maintenance, and upgrading of
                conveyor belt systems, ensuring smooth and efficient transportation of baggage and goods within the
                airport premises.We have also excelled in managing complex panel wiring systems, integrating critical
                electronic components that are essential for the seamless functioning of various airport facilities.
                <br>
                <br>
                These projects exemplify our dedication to delivering high-quality solutions that uphold safety,
                precision, and efficiency at every level. Our focus remains on providing exceptional services to
                airports, contributing to their operational excellence and enabling them to cater to the needs of a
                dynamic and growing aviation industry.
            </div>
        </div>

        <div class="projects">
            <div class="projects__title">Airborne Achievements: Our Airport Projects</div>

            <div class="projects__flex">
                <div class="projects__flex_image">
                    <img src="../../assets/motors.jpg" alt="Cable Dressing">
                </div>
                <div class="projects__flex_desc">
                    <div class="projects__flex_desc_title">
                        <div class="projects__flex_desc_title_point"></div>
                        <div class="projects__flex_desc_title_text">
                            MOPA Airport, Goa
                        </div>
                    </div>
                    <img src="../../assets/motors.jpg" class="projects__flex_desc_image" alt="Cable Dressing">
                    <div class="projects__flex_desc_text">
                        When it comes to managing airports, we're really good at setting up the electrical stuff for the
                        conveyor belts that handle your luggage. It's like making sure your luggage moves smoothly from
                        one place to another.
                        <br><br>
                        Our team has consistently demonstrated precision and proficiency in executing these crucial
                        projects.
                    </div>
                </div>
            </div>

            <div class="projects__flex">
                <div class="projects__flex_desc">
                    <div class="projects__flex_desc_title">
                        <div class="projects__flex_desc_title_point"></div>
                        <div class="projects__flex_desc_title_text">
                            Sardar Vallabhbhai Patel International Airport (Terminal 1),
                            Gujarat
                        </div>
                    </div>
                    <img src="../../assets/cable_dressing.jpg" class="projects__flex_desc_image" alt="Cable Dressing">
                    <div class="projects__flex_desc_text">
                        We're careful planners, making sure everything is wired up just right and works perfectly. This
                        is super important for the airport to run well and ensure your bags get where they need to be
                        without a hitch.
                        <br><br>
                        From meticulously planning the layout to expertly wiring and integrating electronic components,
                        our approach ensures that every part of the system operates in harmony.
                    </div>
                </div>
                <div class="projects__flex_image">
                    <img src="../../assets/cable_dressing.jpg" alt="Cable Dressing">
                </div>
            </div>

            <div class="projects__flex">
                <div class="projects__flex_image">
                    <img src="../../assets/cable_tray.jpg" alt="Cable Dressing">
                </div>
                <div class="projects__flex_desc">
                    <div class="projects__flex_desc_title">
                        <div class="projects__flex_desc_title_point"></div>
                        <div class="projects__flex_desc_title_text">
                            Sardar Vallabhbhai Patel International Airport (Terminal 2),
                            Gujarat
                        </div>
                    </div>
                    <img src="../../assets/cable_tray.jpg" class="projects__flex_desc_image" alt="Cable Dressing">
                    <div class="projects__flex_desc_text">
                        Imagine your luggage effortlessly gliding from check-in to the plane, and that's where we come
                        in. We specialize in making sure the electrical parts of those conveyor belts and systems that
                        handle your bags are top-notch.
                        <br><br>
                        We prioritize safety, reliability, and optimal functionality, guaranteeing that the baggage
                        handling system operates at its best, contributing to the smooth flow of operations within the
                        airport. Our successful track record in this domain is a testament to our dedication to
                        excellence in airport facility management.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>