<div class="main_content_position">
    <div class="page__header">
        <div class="page__header_title">
            Services
        </div>
        <div class="page__header_link">
            <span class="page__header_link_text" routerLink="/">Home</span>
            <span class="material-icons page__header_link-icon">chevron_right</span>
            <span class="page__header_link_text">Services</span>
        </div>
    </div>
</div>
<div class="service">
    <div class="service__flex">
        <div class="service__flex_image">
            <img src="../../assets/system_maintainance.jpg" alt="System Maintainance">
        </div>
        <div class="service__flex_info media__padding">
            <h2 class="service__flex_info_title">Material Handling System Maintainance</h2>
            <img class="service__flex_info_image" src="../../assets/system_maintainance.jpg" alt="System Maintainance">
            <div class="service__flex_info_text">
                AEROPS offers wide range of material handling system maintainance and operations services.
                We are always at your step to keep your system functional 24 * 7 cause proper maintainance of systems is
                essential.
                The startegies we follow
            </div>
            <ul class="service__flex_info_list">
                <li class="service__flex_info_list_item">
                    Our philosophy that emphasizes empowering all factors to seek root causes of problems and recommend
                    small incremental improvements in quality, efficiency, and effectiveness wherever and whenever they
                    are found to upkeep system KPI’s above threshold level.
                </li>
                <li class="service__flex_info_list_item">
                    CMMS : Computerized Maintenance Management System
                </li>
                <li class="service__flex_info_list_item">
                    RCFA : Root cause Failure Analysis.
                </li>
                <li class="service__flex_info_list_item">
                    History Card Method
                </li>
                <li class="service__flex_info_list_item">
                    Solutions to reduce unplanned maintenance.
                </li>
                <li class="service__flex_info_list_item">
                    Optimization of PM,CM,EM maintenance costs.
                </li>
                <li class="service__flex_info_list_item">
                    Consistent approach towards MTBF.
                </li>
            </ul>
        </div>
    </div>

    <div class="service__flex">
        <div class="service__flex_info">
            <h2 class="service__flex_info_title">System Operation Management</h2>
            <img class="service__flex_info_image" src="../../assets/operation_management.jpg" alt="System Maintainance">
            <div class="service__flex_info_text">
                AEROPS offers comprehensive range of support and management service for system to maintain and improve
                its efficiency and ability.
            </div>
            <ul class="service__flex_info_list">
                <li class="service__flex_info_list_item">
                    SCADA Monitoring
                </li>
                <li class="service__flex_info_list_item">
                    Clearing off operational & Maintenance faults.
                </li>
                <li class="service__flex_info_list_item">
                    Bag/Luggage tracking
                </li>
                <li class="service__flex_info_list_item">
                    Management Reports.
                </li>
                <li class="service__flex_info_list_item">
                    Data analysis.
                </li>
                <li class="service__flex_info_list_item">
                    Stakeholder management.
                </li>
                <li class="service__flex_info_list_item">
                    QHSE management & coordination.
                </li>
            </ul>
        </div>
        <div class="service__flex_image">
            <img src="../../assets/operation_management.jpg" alt="System Operation Management">
        </div>
    </div>

    <div class="service__flex">
        <div class="service__flex_image">
            <img src="../../assets/project_management.jpg" alt="Airport Project Management">
        </div>
        <div class="service__flex_info media__padding">
            <h2 class="service__flex_info_title">Airport Project Management</h2>
            <img class="service__flex_info_image" src="../../assets/project_management.jpg" alt="Airport Project Management">

            <div class="service__flex_info_text">
                AEROPS not only concentrates on the construction of the system. But also, We take care of the pre and
                post construction phases with the same intimacy.
            </div>
            <ul class="service__flex_info_list">
                <li class="service__flex_info_list_item">
                    <div style="font-weight: bold">Pre- Construction Phase :</div>
                    <ul>
                        <li>Project administration</li>
                        <li>Design management</li>
                    </ul>
                </li>
                <li class="service__flex_info_list_item">
                    <div style="font-weight: bold">Construction Phase :</div>
                    <ul>
                        <li>Appointment of contractors</li>
                        <li>Planning management</li>
                        <li>Cost management</li>
                        <li>Site management</li>
                        <li>Quality control</li>
                        <li>Safety management</li>
                        <li>Co-ordination with client, architect and other consultants</li>
                        <li>Procurement management</li>
                    </ul>
                </li>
                <li class="service__flex_info_list_item">
                    <div style="font-weight: bold">Post- Construction Phase :</div>
                    <ul>
                        <li>Package closure – Technical & commercial</li>
                        <li>Final cost report</li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>