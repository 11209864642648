<div class="main_content_position">
    <div class="header">
        <div class="header__title">
            Grow with <span style="font-weight: bold;">AEROPSFM</span>
        </div>
    </div>

    <div class="container">

        <div class="career__flex">
            <div class="career__flex_image">
                <img src="../../assets/join_team.png" alt="Join Team">
            </div>

            <div class="career__flex_desc">
                <div class="career__flex_desc_title">Where Extraordinary is Chosen</div>

                <div class="career__flex_desc_text">
                    If you're seeking a dynamic and rewarding career within the aviation industry, you're in the right
                    place. AEROPSFM is a vibrant organization that values talent, innovation, and dedication. As we
                    continue to grow and expand our operations in the realm of airport facility management, we are
                    constantly on the lookout for passionate individuals who can contribute their skills and enthusiasm
                    to our team.
                    <br><br>
                    We provide ample opportunities for professional development, training, and hands-on experience in a
                    supportive and inclusive work environment.
                </div>
            </div>
        </div>

        <div class="benefits">
            <div class="benefits__title">
                <div class="benefits__title_text">
                    Perks & Benefits
                </div>
                <div class="benefits__title_subtext">We Offer</div>
            </div>

            <div class="benefits__flex">
                <div class="benefits__flex_item">
                    <div class="benefits__flex_item_image">
                        <img src="../../assets/accomodation.png" alt="Accomodation">
                    </div>
                    <div class="benefits__flex_item_info">
                        <div class="benefits__flex_item_info_title">Accomodation</div>
                        <div class="benefits__flex_item_info_subtitle">
                            As part of our commitment to employee well-being, we provide accommodation assistance
                            for eligible team members, ensuring a smooth transition and comfortable living
                            arrangements.
                        </div>
                    </div>
                </div>

                <div class="benefits__flex_item">
                    <div class="benefits__flex_item_image">
                        <img src="../../assets/transportation.png" alt="Transportaion">
                    </div>
                    <div class="benefits__flex_item_info">
                        <div class="benefits__flex_item_info_title">Transportaion</div>
                        <div class="benefits__flex_item_info_subtitle">
                            We offer transportation benefits to our employees, ensuring convenient and reliable
                            commuting options that contribute to a seamless work-life balance.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="opening">
            <div class="opening__title">We're <span class="opening__title_italicised">HIRING!</span></div>

            <div class="opening__flex">
                <div class="opening__flex_item">
                    <div class="opening__flex_item_title">
                        Site Engineer
                        <span class="opening__flex_item_title_subtetxt">(MEP/ Airport related experience preferred)</span>
                    </div>

                    <div class="opening__flex_item_desc">
                        <img class="opening__flex_item_desc_image" src="../../assets/education.png" alt="Education">
                        <span class="opening__flex_item_desc_text">BE/Diploma in Electrical</span>
                    </div>

                    <div class="opening__flex_item_desc">
                        <img class="opening__flex_item_desc_image" src="../../assets/briefcase.png" alt="Education">
                        <span class="opening__flex_item_desc_text">1-2 years of experience</span>
                    </div>

                    <div class="opening__flex_item_desc">
                        <img class="opening__flex_item_desc_image" src="../../assets/location.png" alt="Education">
                        <span class="opening__flex_item_desc_text">Goa/ Delhi/ Ahmedabad</span>
                    </div>

                    <div style="text-align: center; margin-top: 2rem;">
                        <a href="mailto:info@aeropsfm.com?subject=Application for Site Engineer&bcc=pradipjad72@gmail.com,pradip.jadhav@aeropsfm.com" target="_blank" class="opening__flex_item_btn">Send Resume</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>